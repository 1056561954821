<template>
  <div class="py-2">
    <b-spinner v-if="spinnerActive" variant="primary" large />
    <vue-apex-charts
      v-else-if="!spinnerActive && chartLength !== 0"
      type="donut"
      width="650"
      :options="chartOptions"
      :series="series"
      :key="chartKey"
    ></vue-apex-charts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapActions, mapGetters } from "vuex";
import * as types from "../store/types";
import { useToast } from "vue-toastification/composition";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    spinnerActive: Boolean,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: {
        search: false,
      },
      chartKey: 0,
      defaultChartItems: [
        { category: "Soluções", total_amount: 0, total_entries: 0 },
        { category: "Entrevista Paga", total_amount: 0, total_entries: 0 },
        { category: "Assinatura", total_amount: 0, total_entries: 0 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      chartItems: types.CHART_COMMISSION_MANAGEMENT,
    }),

    chartLength() {
      return this.chartItems?.length;
    },

    effectiveChartItems() {
      return this.chartLength > 0 ? this.chartItems : this.defaultChartItems;
    },

    series() {
      const items = this.effectiveChartItems;

      const total = items.reduce(
        (acc, item) => acc + (item.total_amount || 0),
        0
      );

      if (total === 0) {
        return [];
      }

      return items
        .map((item) => ({
          category: item.category,
          percentage: parseFloat(
            ((item.total_amount / total) * 100).toFixed(2)
          ),
        }))
        .sort((a, b) => b.percentage - a.percentage)
        .map((item) => item.percentage);
    },

    labels() {
      const items = this.effectiveChartItems;

      return items
        .map((item) => ({
          category: item.category,
          total_amount: item.total_amount,
        }))
        .sort((a, b) => b.total_amount - a.total_amount)
        .map((item) => item.category);
    },

    decreasingChartData() {
      const items = this.effectiveChartItems;

      return items.sort((a, b) => b.total_amount - a.total_amount);
    },

    chartOptions() {
      return {
        chart: {
          type: "donut",
        },
        labels: this.labels,
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            donut: {
              size: "55%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (value, { seriesIndex }) => {
              const item = this.decreasingChartData[seriesIndex];
              if (item) {
                const amount = new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(item.total_amount);
                return `<b style="font-size: 14px;">${item.category} <br/> </b>
                        <i>Participação: ${value}% <br/> Quantidade: ${item.total_entries} <br/> Total: ${amount}</i>`;
              }
              return `${value}%`;
            },
            title: {
              formatter: (seriesName) =>
                ` <b style="display: none;">${seriesName}</b>`,
            },
          },
        },
        legend: {
          formatter: function (val, opts) {
            const label = opts.w.globals.labels[opts.seriesIndex];
            const seriesValue = opts.w.globals.series[opts.seriesIndex];
            return ` ${seriesValue !== undefined ? seriesValue : "0"}% - ${
              label.length > 25 ? label.substring(0, 25) + "..." : label
            }`;
          },
          labels: {
            colors: this.skin === "dark" ? "#fff" : "",
            useSeriesColors: false,
          },
        },
        // theme: {
        //   palette: "palette10",
        // },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
  },
  watch: {
    chartItems: {
      immediate: true,
      handler(newVal) {
        this.chartKey += 1;
      },
    },
  },
};
</script>
